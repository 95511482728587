<template>
  <div v-if="isProjectReady" class="document">
    <DocumentPrintPage
      v-for="(page, index) in pages"
      :key="page.id"
      :fields="getFieldsByPage(index)"
      :project="project"
      :image="page.path"
    />
  </div>
  <div v-else>Keine Daten vorhanden!</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DocumentPrintPage from '@/components/Document/DocumentPrintPage';
import { ExtendedProject } from '@/helpers/projects';

export default {
  name: 'ProjectPrintpage',
  components: {
    DocumentPrintPage,
  },
  props: {
    projectNumber: {
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters(['getProjectByNumber']),
    project() {
      const project = this.getProjectByNumber(this.number);
      if (project) {
        new ExtendedProject(project);
      }
      return project;
    },
    pages() {
      return this.project.powerCompany.netRegistrationExportDocument.pageBackgroundAttachments;
    },
    /** Check prerequesites to generate pdf */
    isProjectReady() {
      return (
        this.project &&
        this.project.powerCompany &&
        this.project.powerCompany.netRegistrationExportDocument &&
        this.project.powerCompany.netRegistrationExportDocument.pageBackgroundAttachments.length > 0
      );
    },
  },
  methods: {
    ...mapActions(['fetchProjectByNumber']),
    getFieldsByPage(index) {
      const page = index + 1;
      return this.project.powerCompany.netRegistrationExportDocument.fields.filter(
        (field) => field.page == page
      );
    },
  },
  async mounted() {
    this.number = this.projectNumber;
    await this.fetchProjectByNumber(this.number);
  },
};
</script>
<style lang="scss" scoped>
.document {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
